'use client';

import {useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import {useScrolling} from 'react-use';
import {FreeTierPlaceholder} from '~/app/(unauthenticated)/job-board/_/components/job-board-listing/free-tier-placeholder';
import {JobBoardListingHeader} from '~/app/(unauthenticated)/job-board/_/components/job-board-listing/header';
import {JobBoardListingLabels} from '~/app/(unauthenticated)/job-board/_/components/job-board-listing/labels';
import {PATHS} from '~/shared/constants/paths';
import {useInfiniteJobBoardJobs} from '~/shared/data/job-board';
import {useSubscription} from '~/shared/data/subscription';
import {JobBoardFilterContext} from '~/shared/providers/job-board-filter-provider';
import type {JobBoardFilterConfig} from '~/shared/types/job-board-filter';
import {Card, Empty, TextButton} from '@job-ish/ui/components';
import {IconAdjustmentsSearch, IconExclamationCircle} from '@tabler/icons-react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {useParams, useRouter} from 'next/navigation';
import {Virtualizer} from 'virtua';

import type {VirtualizerHandle} from 'virtua';
import {JobBoardLoading} from './loading';
import {JobBoardLoadingItem} from './loading-item';

dayjs.extend(relativeTime);

export type JobBoardProps = {
	persistedFilter: JobBoardFilterConfig;
};

export const JobBoard = ({persistedFilter}: JobBoardProps) => {
	const parentRef = useRef<HTMLDivElement>(null);
	const virtualizerRef = useRef<VirtualizerHandle>(null);
	const {loading} = useContext(JobBoardFilterContext);
	const {data: subscription} = useSubscription();
	const scrolling = useScrolling(parentRef);
	const router = useRouter();
	const params = useParams();
	const fetching = useRef(false);

	const jobId = Number(params.id);
	const styleRef = useRef<HTMLStyleElement>(null);

	useEffect(() => {
		if (params.id && styleRef.current) {
			styleRef.current.innerHTML = `
        #job-${jobId} {
          background-color: var(--mauve-4);
        }
      `;
		}
	}, [jobId, params.id]);

	const {data, isLoading, isError, isFetchingNextPage, fetchNextPage} =
		useInfiniteJobBoardJobs(persistedFilter);

	useEffect(() => {
		if (fetching.current && !isFetchingNextPage) {
			fetching.current = false;
		}
	}, [isFetchingNextPage]);

	const jobBoardJobs = useMemo(() => {
		if (!data) return null;

		const uniqueJobsById = new Map();

		data.pages.forEach(page => {
			if (page) {
				page.data.results.forEach(job => {
					if (!uniqueJobsById.has(job.id)) {
						uniqueJobsById.set(job.id, job);
					}
				});
			}
		});

		return {...data.pages[0], data: {...data.pages[0]?.data, results: [...uniqueJobsById.values()]}};
	}, [data]);

	const jobCount = useMemo(
		() => data?.pages.reduce((acc, page) => (page ? (acc += page.data.results.length) : acc), 0) || 20,
		[data?.pages],
	);

	const filteredJobCount = useMemo(
		() => (jobBoardJobs?.data.count ? Math.floor((jobBoardJobs?.data.count + 4) / 5) : 0),
		[jobBoardJobs?.data.count],
	);

	const showLoadingSkeleton = useMemo(() => isLoading || loading, [isLoading, loading]);

	const handleScroll = useCallback(() => {
		const handle = virtualizerRef.current;
		const end = handle?.findEndIndex();
		if (
			end &&
			end + 10 >= jobCount - 1 &&
			jobBoardJobs?.data.next &&
			!isFetchingNextPage &&
			data?.pages.at(-1)?.data.results.length &&
			!fetching.current
		) {
			fetching.current = true;
			fetchNextPage();
		}
	}, [data?.pages, fetchNextPage, isFetchingNextPage, jobBoardJobs?.data.next, jobCount]);

	if (showLoadingSkeleton) {
		return <JobBoardLoading />;
	}

	if (isError || jobBoardJobs?.status !== 200) {
		return (
			<div className="h-full w-full p-[1px]">
				<Card bordered className="h-full w-full">
					<Empty
						className="h-full w-full"
						icon={IconExclamationCircle}
						size="lg"
						subtitle="Something went wrong while fetching jobs from the server. Please try again later."
						title="Error Loading Jobs"
					>
						<TextButton color="primary" onPress={() => router.refresh()}>
							Try Again
						</TextButton>
					</Empty>
				</Card>
			</div>
		);
	}

	return (
		<div
			className={clsx('h-full flex-grow overflow-y-auto overscroll-contain', jobId && 'hidden lg:block')}
			ref={parentRef}
		>
			<style ref={styleRef}>
				{`#job-${jobId} {
            background-color: var(--mauve-5);
        }`}
			</style>
			{!!jobBoardJobs?.data.count && jobBoardJobs.data.count > 0 ? (
				<div className="w-full p-[1px]">
					<Virtualizer
						count={jobCount}
						onScroll={handleScroll}
						overscan={10}
						ref={virtualizerRef}
						scrollRef={parentRef}
					>
						{jobBoardJobs.data.results.map((job, index) =>
							index % 5 === 1 && subscription?.status !== 'active' && filteredJobCount > 0 ? (
								<div
									className={clsx(
										index === 0
											? 'mb-1.5'
											: index === jobBoardJobs.data.results.length - 1
												? 'mt-1.5'
												: 'my-1.5',
									)}
									key={job.id}
								>
									<FreeTierPlaceholder filteredCount={filteredJobCount} />
								</div>
							) : (
								<Card
									bordered
									className={clsx(
										'w-full cursor-pointer gap-2 shadow-sm shadow-mauve9 transition-colors hover:bg-mauve4 focus:bg-mauve4',
										index === 0
											? 'mb-1.5'
											: index === jobBoardJobs.data.results.length - 1
												? jobBoardJobs.data.next
													? 'my-1.5'
													: 'mt-1.5'
												: 'my-1.5',
									)}
									id={`job-${job.id}`}
									key={job.id}
									onClick={() => {
										router.push(`${PATHS.JobBoard}/${job.id}`);
									}}
									onFocus={() => {
										if (!scrolling) {
											router.prefetch(`${PATHS.JobBoard}/${job.id}`);
										}
									}}
									onMouseOver={() => {
										if (!scrolling) {
											router.prefetch(`${PATHS.JobBoard}/${job.id}`);
										}
									}}
									shadow
									tabIndex={0}
								>
									<JobBoardListingHeader iconsOnly job={job} />
									<Card.Body className="flex w-full flex-wrap gap-1 overflow-hidden">
										<JobBoardListingLabels job={job} />
									</Card.Body>
								</Card>
							),
						)}
						{jobBoardJobs.data.next && (
							<div className="mt-1.5">
								<JobBoardLoadingItem />
							</div>
						)}
					</Virtualizer>
				</div>
			) : (
				<Empty
					icon={IconAdjustmentsSearch}
					subtitle="No jobs found for the selected filters. Please try adjusting your search criteria."
					title="No Jobs Found"
				/>
			)}
		</div>
	);
};
